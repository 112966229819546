//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import HeaderLogo from '@/components/next/layout/cogwheel/HeaderLogo'
import CogwheelMenu from '@/components/next/layout/cogwheel/CogwheelMenu'
import NavigationTopItems from '@/components/next/layout/navigation/NavigationTopItems'
import NavigationBottomItems from '@/components/next/layout/navigation/NavigationBottomItems'
import LegalLinks from '@/components/next/layout/legal/LegalLinks'
import EnrolmentBranding from '@/components/next/layout/enrolment/EnrolmentBranding'
import FlashSaleSidebarBanner from '@/components/next/layout/flashsale/FlashSaleSidebarBanner.vue'
import NavigationCertificatesStandaloneTopItems
  from '@/components/next/layout/navigation/NavigationCertificateStandaloneTopItems.vue'

export default {
  name: 'LayoutSidebar',
  components: {
    NavigationCertificatesStandaloneTopItems,
    HeaderLogo,
    CogwheelMenu,
    NavigationTopItems,
    NavigationBottomItems,
    LegalLinks,
    EnrolmentBranding,
    FlashSaleSidebarBanner
  },

  data () {
    return {
      workspace: null
    }
  }
}
