//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CfModal from '~/components/next/ui/modal/CfModal.vue'
import CfModalHeading from '~/components/next/ui/modal/CfModalHeading.vue'
import CfExplanation from '~/components/next/ui/text/CfExplanation.vue'
import CfButton from '~/components/next/ui/button/CfButton.vue'
import DeterminesBillingSituation from '~/components/next/workspace/billing/subscription/DeterminesBillingSituation'
import SubscriptionCycleChooser
  from '~/components/next/workspace/billing/subscription/prices/SubscriptionCycleChooser.vue'

export default {
  name: 'AdditionalCertificatesPersonsModal',
  components: { SubscriptionCycleChooser, CfButton, CfExplanation, CfModalHeading, CfModal },
  mixins: [DeterminesBillingSituation],
  props: {
    limitReached: Boolean
  },
  data () {
    const subscription = this.$auth.user.workspace.subscription

    return {
      selectedQuantity: subscription ? subscription.additional_certificates_persons : 100,
      manuallySelectedCycle: 'yearly',
      submitting: false
    }
  },
  computed: {
    includedPersons () {
      return this.$auth.user.workspace.product.included_certificates_persons
    },
    canBeSubmitted () {
      return this.canChooseQuantity && this.availableQuantities.includes(this.selectedQuantity)
    },
    availableQuantities () {
      const quantities = []

      if (!this.$access.isCertificatesStandalone()) {
        quantities.push(0)
      }

      quantities.push(100)
      quantities.push(200)
      quantities.push(300)
      quantities.push(400)
      quantities.push(500)
      quantities.push(600)
      quantities.push(700)

      return quantities
    },
    contactUsQuantity () {
      const max = Math.max(...this.availableQuantities) + this.includedPersons + this.packageSize
      return max + '+'
    },
    packageSize () {
      return 100
    },
    canChangeCycle () {
      return this.$access.isCertificatesStandalone()
    },
    canChooseQuantity () {
      return this.$access.isCertificatesStandalone() || this.subscriptionRecurring
    },
    cycle: {
      get () {
        if (this.canChangeCycle) {
          return this.manuallySelectedCycle
        }

        return this.$auth.user.workspace.subscription.cycle
      },
      set (cycle) {
        this.manuallySelectedCycle = cycle
      }
    },
    buttonText () {
      if (this.hasPaymentMethod) {
        return this.$t('workspace.billing.additional_certificates_persons.modal_action_subscribe')
      }

      return this.$t('workspace.billing.additional_certificates_persons.modal_action_proceed_to_payment')
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    selectQuantity (quantity) {
      this.selectedQuantity = quantity
    },
    isSelected (quantity) {
      return this.selectedQuantity === quantity
    },
    formattedPrice (quantity) {
      if (quantity === 0) {
        return this.$t('workspace.billing.additional_certificates_persons.included')
      }

      const monthlyPrice = 7
      const yearlyPrice = 5 * 12

      const packages = Math.ceil(quantity / 100)

      const amount = this.cycle === 'yearly'
        ? packages * yearlyPrice
        : packages * monthlyPrice

      if (this.$access.isCertificatesStandalone()) {
        return this.$t('workspace.billing.additional_certificates_persons.standalone_price_' + this.cycle, { amount })
      }

      return this.$t('workspace.billing.additional_certificates_persons.price_' + this.cycle, { amount })
    },
    openChat () {
      this.$chatwoot()
    },
    async submit () {
      this.submitting = true
      try {
        const response = await this.$api.additionalCertificatesPersons.update({
          additional_certificates_persons: this.selectedQuantity,
          cycle: this.cycle
        })

        if (response.status === 200) {
          location.assign(response.data.redirect_url)
        } else {
          await this.$auth.fetchUser()
          this.close()
        }
      } catch (err) {
        this.$errors.handle(err)
      } finally {
        this.submitting = false
      }
    }
  }
}
