//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CfModal from '@/components/next/ui/modal/CfModal.vue'
import CfExternalLinkButton from '@/components/next/ui/button/CfExternalLinkButton.vue'
import IconButton from '@/components/common/buttons/IconButton.vue'
import HandlesDismissals from '@/components/next/utilities/HandlesDismissals'

export default {
  name: 'AnnoucementsModal',
  components: {
    IconButton,
    CfModal,
    CfExternalLinkButton
  },
  mixins: [HandlesDismissals],
  props: {
    announcements: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      index: 0,
      announcementImages: {}
    }
  },
  computed: {
    announcement () {
      return this.announcements[this.index]
    },

    imageStyle () {
      const imageUrl = this.announcementImages[this.announcement.id]

      if (!imageUrl) {
        return {}
      }

      return {
        'background-image': `url(${imageUrl})`,
        'background-size': 'cover',
        'background-position': 'center'
      }
    },

    firstPage () {
      return this.index === 0
    },

    lastPage () {
      return this.index === this.announcements.length - 1
    },

    pageCounterText () {
      const currentPage = String(this.index + 1)
      const totalPages = String(this.announcements.length)

      return currentPage + '/' + totalPages
    },

    navigationVisible () {
      return this.announcements.length > 1
    }
  },

  watch: {
    announcement: {
      immediate: true,
      handler (announcement) {
        this.dismiss(announcement.dismissal_key)
        this.loadAnnouncementImage(announcement)
      }
    }
  },

  methods: {
    async fetchAnnouncementImage (announcement) {
      const axios = this.$axios.create()
      axios.setToken(false)
      const response = await axios.get(this.announcement.header_image_url, {
        responseType: 'blob'
      })
      const blob = new Blob([response.data])
      return URL.createObjectURL(blob)
    },

    async loadAnnouncementImage (announcement) {
      if (this.announcementImages[announcement.id]) {
        return
      }

      const imageUrl = await this.fetchAnnouncementImage(announcement)
      this.$set(this.announcementImages, announcement.id, imageUrl)
    },

    nextPage () {
      if (this.lastPage) {
        return
      }

      this.index++
    },

    previousPage () {
      if (this.firstPage) {
        return
      }

      this.index--
    },

    close () {
      this.$emit('close')
    }
  }
}
