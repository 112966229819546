//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LegalLink from '@/components/next/layout/legal/LegalLink'

export default {
  name: 'LegalLinks',
  components: { LegalLink }
}
