//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CfLinkButton from '@/components/next/ui/button/CfLinkButton.vue'

export default {
  name: 'SubscriptionEphemeralWorkspace',
  components: {
    CfLinkButton
  },
  computed: {
    registrationUrl () {
      return this.$access.isCertificatesStandalone()
        ? '/auth/register?certificates_standalone=1'
        : '/auth/register'
    }
  }
}
