//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FlashSaleUtils from '@/components/next/layout/flashsale/FlashSaleUtils'

export default {
  name: 'FlashSaleSubscriptionBanner',
  mixins: [FlashSaleUtils]
}
