//
//
//
//
//
//
//
//
//
//

import CfButton from '~/components/next/ui/button/CfButton.vue'

export default {
  name: 'CfExternalLinkButton',
  components: {
    CfButton
  },
  extends: CfButton,
  props: {
    href: {
      type: String,
      default: null
    }
  }
}
