//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'OnboardingStep',
  props: {
    index: {
      type: Number,
      required: true
    },
    step: {
      type: String,
      required: true
    },
    expanded: Boolean,
    complete: Boolean
  },
  computed: {
    completionIcon () {
      if (this.complete) {
        return 'check-circle'
      }

      return 'circle-' + (this.index + 1)
    }
  }
}
