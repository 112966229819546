//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'UiButton',
  props: {
    label: {
      type: String,
      default: null
    },
    icon: {
      type: [String, Array],
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    fullwidth: {
      type: Boolean,
      default: false
    },
    look: {
      type: String,
      default: 'default'
    },
    type: {
      type: String,
      default: 'button'
    }
  },
  computed: {
    classes () {
      let classes = 'flex justify-center py-2 px-4 font-medium text-sm rounded-md truncate relative transition ease-in-out duration-20 '

      if ((this.look === 'text' || this.look === 'danger_text') && this.disabled) {
        return classes + 'text-gray-500'
      }

      if (this.look === 'text') {
        return classes + 'text-gray-700 focus:bg-gray-50 hover:bg-gray-50'
      }

      if (this.look === 'danger_text') {
        return classes + 'text-red-700 focus:bg-red-50 hover:bg-red-50'
      }

      classes += 'border shadow-sm '
      classes += this.fullwidth ? 'w-full ' : 'w-fit '

      if (this.disabled) {
        classes += 'text-white bg-gray-300 cursor-auto'
      } else {
        switch (this.look) {
          case 'primary':
            classes += 'text-white bg-cfblue-900 focus:bg-cfblue-600 hover:bg-cfblue-600 border-transparent'
            break
          case 'danger':
            classes += 'text-white bg-red-600 focus:bg-red-700 hover:bg-red-700 border-transparent'
            break
          case 'default':
          default:
            classes += 'text-gray-700 bg-white focus:bg-gray-50 hover:bg-gray-50 border-gray-300'
            break
        }
      }

      return classes
    }
  },
  methods: {
    handleClick () {
      if (this.loading || this.disabled) {
        return
      }

      this.$emit('click')
    }
  }
}
