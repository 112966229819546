//
//
//
//
//
//

export default {
  name: 'CfCircledIcon',
  props: {
    icon: {
      type: [String, Array],
      default: 'question'
    },
    color: {
      type: String,
      default: 'red'
    },
    backgroundColor: {
      type: String,
      default: null
    },
    iconColor: {
      type: String,
      default: null
    }
  },
  computed: {
    classes () {
      const backgroundColor = this.backgroundColor || this.color + '-100'
      const iconColor = this.iconColor || this.color + '-900'

      return 'bg-' + backgroundColor + ' text-' + iconColor
    }
  }
}
