//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'HeaderLogo',
  data () {
    return {
      success: false
    }
  },
  methods: {
    handleClick (event) {
      // Ctrl+Click on logo copies the workspace id to clipboard
      if (event.metaKey) {
        event.preventDefault()
        navigator.clipboard.writeText(this.$auth.user.workspace.id)
        this.success = true
        setTimeout(() => { this.success = false }, 300)
      }
    }
  }
}
