//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LayoutSidebar from '@/components/next/layout/LayoutSidebar'
import SupportButton from '@/components/next/layout/support/SupportButton'
import EphemeralBanner from '@/components/next/layout/ephemeral/EphemeralBanner.vue'
import FinanceOnboardingBanner from '@/components/next/layout/finance_onboarding/FinanceOnboardingBanner'
import CfModalRoot from '@/components/next/ui/modal/CfModalRoot.vue'

export default {
  components: {
    LayoutSidebar,
    SupportButton,
    EphemeralBanner,
    FinanceOnboardingBanner,
    CfModalRoot
  },
  head: {
    meta: [
      { name: 'viewport', content: 'width=1200, initial-scale=1' }
    ]
  },
  computed: {
    ephemeralBanner () {
      return this.$auth.user?.workspace?.demo?.ephemeral
    }
  }
}
