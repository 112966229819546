export default $axios => ({
  index (listId, params, config) {
    return $axios.get(`lists/${listId}/persons`, {
      params,
      ...config
    })
  },

  export (listId, params) {
    return $axios.get(`lists/${listId}/persons/export`, { params })
  },

  store (listId, data) {
    return $axios.post(`lists/${listId}/persons`, data)
  },

  show (personId) {
    return $axios.get(`persons/${personId}`)
  },

  update (personId, data) {
    return $axios.put(`persons/${personId}`, data)
  }
})
