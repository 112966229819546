//
//
//
//
//
//
//
//
//
//
//
//
//
//

import UiButton from '@/components/common/UiButton'

export default {
  name: 'SupportButton',
  components: { UiButton },
  computed: {
    onFormbuilderPage () {
      const regex = /\/forms\/.*/gm
      return regex.test(this.$route.path)
    }
  },
  methods: {
    openSupportChat () {
      this.$chatwoot()
    }
  }
}
