export default {
  save: 'Speichern',
  done: 'Fertig',
  cancel: 'Abbrechen',
  close: 'Schließen',
  next: 'Weiter',
  back: 'Zurück',

  learn_more: 'Mehr erfahren',

  vimeo_disclaimer: 'Mit dem Abspielen werden Inhalte über unseren Dienstleister Vimeo geladen.',

  pagination: {
    load_more: 'Weitere laden',
    load_more_count: '{count} weitere laden',
    thats_all: 'Das ist alles!'
  },

  network_error: {
    title: 'Keine Internetverbindung',
    explanation: 'campflow benötigt eine stabile Internetverbindung, um richtig zu funktionieren. Bitte überprüfe deine Verbindung und lade die Seite neu.',
    reload_now: 'Jetzt neu laden'
  },

  generic_error: {
    title: 'Whoops!',
    explanation: 'Ein Fehler ist aufgetreten. Versuche es erneut und kontaktiere den Support, falls der Fehler nochmal auftritt.'
  },

  api_revision_error: {
    title: 'Ein Update ist verfügbar',
    explanation: 'Bitte lade die Seite neu, um die neueste Version von campflow zu verwenden.',
    explanation2: 'Möglicherweise dauert es einige Minuten, bis das Update verfügbar ist. Sollte diese Meldung weiterhin erscheinen, leere bitte deinen Browser-Cache.',
    reload_now: 'Jetzt neu laden',
    ignore: 'Ignorieren'
  }
}
