//
//
//
//
//
//

export default {
  name: 'CfPageHeading'
}
