//
//
//
//
//
//
//
//

export default {
  name: 'NavigationSubItems',
  props: {
    expanded: Boolean
  }
}
