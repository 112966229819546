//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CfButton from '~/components/next/ui/button/CfButton.vue'
import DeterminesBillingSituation from '@/components/next/workspace/billing/subscription/DeterminesBillingSituation'

export default {
  name: 'SubscriptionActions',
  components: { CfButton },
  mixins: [DeterminesBillingSituation],
  props: {
    submitFlow: {
      type: String,
      default: 'subscribe'
    },
    canCompare: Boolean,
    submitting: Boolean
  },
  computed: {
    submitButtonText () {
      if (this.submitFlow !== 'subscribe') {
        return this.$t('workspace.billing.subscription.submit_button.' + this.submitFlow)
      }

      if (!this.hasPaymentMethod) {
        return this.$t('workspace.billing.subscription.submit_button.proceed_to_payment')
      }

      return this.$t('workspace.billing.subscription.submit_button.subscribe')
    }
  },
  methods: {
    submit () {
      this.$emit('submit')
    }
  }
}
