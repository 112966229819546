export default (ctx, inject) => {
  const auth = ctx.$auth

  const access = {
    hasPermission (permission) {
      if (!auth.user) {
        return false
      }

      return auth.user.permissions.includes(permission)
    },

    doesntHavePermission (permission) {
      return !this.hasPermission(permission)
    },

    hasFeature (feature) {
      if (!auth.user) {
        return false
      }

      return auth.user.workspace.product.features.includes(feature)
    },

    doesntHaveFeature (feature) {
      return !this.hasFeature(feature)
    },

    isCertificatesStandalone () {
      if (!auth.user) {
        return false
      }

      return auth.user.workspace.product.flavor === 'certificates'
    }
  }

  inject('access', access)
}
