import { render, staticRenderFns } from "./EnrolmentBranding.vue?vue&type=template&id=04f05524&scoped=true&"
import script from "./EnrolmentBranding.vue?vue&type=script&lang=js&"
export * from "./EnrolmentBranding.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04f05524",
  null
  
)

export default component.exports