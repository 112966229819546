//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import NavigationItems from '@/components/next/layout/navigation/items/NavigationItems'
import NavigationItem from '@/components/next/layout/navigation/items/NavigationItem'
import NavigationSubItems from '@/components/next/layout/navigation/items/NavigationSubItems'
import NavigationSubItem from '@/components/next/layout/navigation/items/NavigationSubItem'
import HandlesDismissals from '@/components/next/utilities/HandlesDismissals'

export default {
  name: 'NavigationTopItems',
  components: { NavigationItems, NavigationItem, NavigationSubItems, NavigationSubItem },
  mixins: [HandlesDismissals],
  computed: {
    onEventPage () {
      if (this.onMemberPage) {
        return false
      }

      return this.$route.path.startsWith('/lists') || this.$route.path.startsWith('/forms')
    },

    onMemberPage () {
      const memberSectionUrls = [
        this.memberListUrl,
        this.memberFormUrl,
        this.contactListUrl,
        this.hierarchyUrl
      ]

      return memberSectionUrls.includes(this.$route.path)
    },

    onContactPage () {
      return this.$route.path === this.contactListUrl
    },

    onFinancePage () {
      return this.$route.path.startsWith('/finance')
    },

    memberListUrl () {
      return `/lists/${this.$auth.user?.workspace?.member_list_id}`
    },

    memberFormUrl () {
      return `/forms/${this.$auth.user?.workspace?.member_form_id}`
    },

    contactListUrl () {
      return `/lists/${this.$auth.user?.workspace?.contact_list_id}`
    },

    hierarchyUrl () {
      return '/workspace/hierarchy'
    }
  }
}
