//
//
//
//
//
//
//
//
//
//

export default {
  name: 'NavigationSubItem',
  props: {
    route: {
      type: String,
      default: ''
    }
  },
  computed: {
    activeClass () {
      return this.route ? 'bg-indigo-900' : null
    }
  },
  methods: {
    handleClick () {
      this.$emit('click')
    }
  }
}
