//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SubscriptionCycleChooser from '@/components/next/workspace/billing/subscription/prices/SubscriptionCycleChooser'
import SubscriptionPriceItem from '@/components/next/workspace/billing/subscription/prices/SubscriptionPriceItem'
import SubscriptionPriceGhost from '@/components/next/workspace/billing/subscription/prices/SubscriptionPriceGhost'
import CfModalHeading from '~/components/next/ui/modal/CfModalHeading.vue'
import PoolBranding from '@/components/next/workspace/billing/subscription/prices/PoolBranding'

const preferredOrder = {
  express: 1,
  pro: 2,
  books: 3
}

export default {
  name: 'SubscriptionPriceChooser',
  components: { SubscriptionCycleChooser, CfModalHeading, PoolBranding, SubscriptionPriceItem, SubscriptionPriceGhost },
  props: {
    prices: {
      type: Array,
      required: true
    },
    value: {
      type: String,
      default: null
    },
    nullFlavor: {
      type: String,
      default: null
    },
    pool: {
      type: Object,
      default: null
    },
    loading: Boolean
  },

  data () {
    return {
      manuallySelectedCycle: 'yearly'
    }
  },

  computed: {
    visiblePrices () {
      const prices = []

      if (this.hasNullPrice) {
        prices.push(this.nullPrice)
      }

      prices.push(...this.prices.filter(price => price.cycle === this.selectedCycle))

      prices.sort((a, b) => {
        return (preferredOrder[a.flavor] || 10) - (preferredOrder[b.flavor] || 10)
      })

      return prices
    },

    selectedPriceId: {
      get () {
        return this.value
      },
      set (priceId) {
        this.$emit('input', priceId)
      }
    },

    selectedPrice: {
      get () {
        return this.prices.find(price => price.id === this.selectedPriceId)
      },
      set (price) {
        this.selectedPriceId = price?.id || null
      }
    },

    ghostCount () {
      if (this.loading) {
        return 1
      }

      return 0
    },

    anyPrices () {
      return this.prices.length > 0
    },

    cycleSet () {
      return new Set(this.prices.map(price => price.cycle))
    },

    lockedCycle () {
      if (!this.anyPrices) {
        return 'yearly'
      }

      if (this.cycleSet.size === 1) {
        return this.prices[0].cycle
      }

      return null
    },

    selectedCycle: {
      get () {
        return this.lockedCycle || this.manuallySelectedCycle
      },
      set (cycle) {
        this.manuallySelectedCycle = cycle
      }
    },

    hasNullPrice () {
      return this.nullFlavor !== null
    },

    nullPrice () {
      return {
        id: null,
        flavor: this.nullFlavor,
        cycle: 'yearly',
        amount: 0,
        monthly_amount: 0,
        yearly_amount: 0,
        anchor_amount: 0
      }
    },

    zeroAmountsHidden () {
      // This is hard-coded for now, because the BDKJ Hamburg is the only pool that takes care of billing themselves,
      // so we don't want to show the 0€ price for them, because it would confuse suggesting that it's free
      return this.pool?.id === 'bdkj-hamburg'
    },

    columnsClass () {
      if (this.visiblePrices.length >= 3) {
        return 'grid-cols-3'
      } else if (this.visiblePrices.length === 2) {
        return 'grid-cols-2'
      } else {
        return 'grid-cols-1'
      }
    }
  },

  watch: {
    prices: {
      immediate: true,
      handler () {
        if (!this.nullFlavor && this.selectedPriceId === null) {
          this.selectPreferredPrice('pro')
        }
      }
    },

    selectedCycle () {
      this.selectPreferredPrice(this.selectedPrice?.flavor)
    }
  },

  methods: {
    selectPreferredPrice (preferredFlavor) {
      const priceWithPreferredFlavor = this.visiblePrices
        .find(price => price.flavor === preferredFlavor)

      this.selectedPrice = priceWithPreferredFlavor || this.visiblePrices[0] || null
    },

    isSelected (price) {
      return this.selectedPriceId === price?.id || null
    },

    select (price) {
      this.selectedPrice = price
    }
  }
}
