//
//
//
//
//
//
//
//
//
//

import CfModalHeading from '~/components/next/ui/modal/CfModalHeading.vue'
import CfExplanation from '~/components/next/ui/text/CfExplanation.vue'
import PoolLogo from '@/components/next/layout/enrolment/PoolLogo'

export default {
  name: 'PoolBranding',
  components: {
    CfModalHeading,
    CfExplanation,
    PoolLogo
  },

  props: {
    pool: {
      type: Object,
      required: true
    },
    prices: {
      type: Array,
      required: true
    }
  },
  computed: {
    poolName () {
      return this.pool.name
    },
    disclaimerText () {
      if (this.allPricesFree) {
        return this.$t('workspace.billing.enrolment.disclaimer_free', { pool: this.poolName })
      }

      return this.$t('workspace.billing.enrolment.disclaimer_paid', { pool: this.poolName })
    },
    allPricesFree () {
      return this.prices.every(price => price.amount === 0)
    }
  }
}
