import { render, staticRenderFns } from "./NavigationTopItems.vue?vue&type=template&id=79b3c8f6&scoped=true&"
import script from "./NavigationTopItems.vue?vue&type=script&lang=js&"
export * from "./NavigationTopItems.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79b3c8f6",
  null
  
)

export default component.exports