//
//
//
//
//
//
//
//
//
//
//
//
//

import CfCircledIcon from '@/components/next/ui/icon/CfCircledIcon.vue'

export default {
  name: 'CfAsideIcon',
  components: {
    CfCircledIcon
  },
  props: {
    color: {
      type: String,
      required: true
    },
    icon: {
      type: [String, Array],
      required: true
    }
  }
}
