//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CfModal from '~/components/next/ui/modal/CfModal.vue'
import CfCircledIcon from '~/components/next/ui/icon/CfCircledIcon.vue'
import CfModalHeading from '~/components/next/ui/modal/CfModalHeading.vue'
import CfExplanation from '~/components/next/ui/text/CfExplanation.vue'
import CfButton from '~/components/next/ui/button/CfButton.vue'

export default {
  name: 'CfConfirmationModal',
  components: {
    CfModal,
    CfCircledIcon,
    CfModalHeading,
    CfExplanation,
    CfButton
  },
  props: {
    heading: {
      type: String,
      required: true
    },
    explanation: {
      type: String,
      required: true
    },
    explanation2: {
      type: String,
      default: null
    },
    action: {
      type: String,
      required: true
    },
    cancel: {
      type: String,
      default: null
    },
    icon: {
      type: [String, Array],
      default: 'times'
    },
    iconColor: {
      type: String,
      default: null
    },
    look: {
      type: String,
      default: 'primary' // primary, light, danger
    }
  },
  computed: {
    buttonLook () {
      switch (this.look) {
        case 'danger':
          return 'danger'
        case 'light':
          return 'default'
        case 'primary':
        default:
          return 'primary'
      }
    },
    iconColorWithFallback () {
      return this.iconColor || (this.look === 'danger' ? 'red' : 'blue')
    },
    cancelLabel () {
      return this.cancel ?? this.$t('common.cancel')
    }
  },
  methods: {
    confirm () {
      this.$emit('close', true)
    },
    close () {
      this.$emit('close', false)
    }
  }
}
