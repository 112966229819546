import { render, staticRenderFns } from "./SubscriptionPriceItem.vue?vue&type=template&id=074940ba&scoped=true&"
import script from "./SubscriptionPriceItem.vue?vue&type=script&lang=js&"
export * from "./SubscriptionPriceItem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "074940ba",
  null
  
)

export default component.exports