//
//
//
//
//
//

export default {
  name: 'PoolLogo',
  props: {
    pool: {
      type: Object,
      required: true
    }
  },
  computed: {
    hasLogo () {
      return this.logoUrl !== null
    },
    logoUrl () {
      return this.pool.logo
    },
    name () {
      return this.pool.name
    }
  }
}
