//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'CfModalRoot',
  computed: {
    modals () {
      return this.$store.getters['modals/openedModals']
    }
  },
  watch: {
    $route () {
      this.$store.commit('modals/forceCloseAll')
    },
    modals (modals) {
      if (modals.length) {
        document.documentElement.classList.add('overflow-hidden')
      } else {
        document.documentElement.classList.remove('overflow-hidden')
      }
    }
  },
  methods: {
    handleClose (modalId, payload) {
      this.$store.commit('modals/close', { modalId, payload })
    }
  }
}
