//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'CfToggle',
  props: {
    value: Boolean,
    disabled: Boolean,
    small: Boolean,
    loading: Boolean
  },
  computed: {
    containerClasses () {
      return {
        'bg-cfblue-900': this.value,
        'bg-gray-300': !this.value,
        'cursor-default opacity-50': this.disabled,
        'cursor-pointer': !this.disabled,
        'h-5 w-10': !this.small,
        'h-3 w-6': this.small
      }
    },
    knobClasses () {
      return {
        'translate-x-5': this.value && !this.small,
        'translate-x-3': this.value && this.small,
        'translate-x-0': !this.value,
        'opacity-50': this.loading,
        'h-4 w-4': !this.small,
        'h-2 w-2': this.small
      }
    }
  },
  methods: {
    toggle () {
      if (this.disabled || this.loading) {
        return
      }

      this.$emit('input', !this.value)
    }
  }
}
