//
//
//
//
//
//

export default {
  name: 'CfParagraph'
}
