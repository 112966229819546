window.chatwootSettings = {
  baseDomain: '.campflow.de'
}

export default (ctx, inject) => {
  const auth = ctx.$auth

  // Slightly modified chatwoot embed snippet
  const BASE_URL = 'https://support.campflow.gmbh'
  const g = document.createElement('script')
  const s = document.getElementsByTagName('script')[0]

  g.src = BASE_URL + '/packs/js/sdk.js'
  g.defer = true
  g.async = true
  s.parentNode.insertBefore(g, s)
  g.onload = function () {
    window.chatwootSDK.run({
      websiteToken: '2XyaiCv1hecqdcN7rJTzAUjf',
      baseUrl: BASE_URL
    })
  }

  window.addEventListener('chatwoot:ready', function () {
    window.$chatwoot.toggleBubbleVisibility('hide')
  })

  const openChat = (customAttributes = null) => {
    if (auth.user) {
      const userDetails = {
        email: auth.user.email,
        name: auth.user.name,
        company_name: auth.user.workspace.name,
        identifier_hash: auth.user.chatwoot_identifier_hash
      }

      window.$chatwoot.setUser(auth.user.id, userDetails)
    }

    if (customAttributes) {
      window.$chatwoot.setCustomAttributes(customAttributes)
    }

    window.$chatwoot.toggle('show')
  }

  inject('chatwoot', openChat)
}
