//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'CfButton',
  props: {
    label: {
      type: String,
      default: null
    },
    icon: {
      type: [String, Array],
      default: null
    },
    look: {
      type: String,
      default: 'default'
    },
    disabled: Boolean,
    loading: Boolean,
    fullWidth: Boolean,
    submitsForm: Boolean
  },
  computed: {
    buttonType () {
      return this.submitsForm ? 'submit' : 'button'
    },
    buttonClasses () {
      return {
        'flex justify-center py-2 px-4 font-medium text-sm rounded-md truncate relative transition ease-in-out duration-20 border': true,

        'w-full': this.fullWidth,
        'w-fit flex-none': !this.fullWidth,

        'shadow-sm': this.look === 'default',
        'border-transparent': ['text', 'danger_text'].includes(this.look),

        'text-gray-400 bg-gray-100 cursor-auto': this.look === 'default' && this.disabled,
        'text-white bg-gray-300 cursor-auto': ['primary', 'danger'].includes(this.look) && this.disabled,
        'text-gray-500': ['text', 'danger_text'].includes(this.look) && this.disabled,

        'text-gray-700 bg-white hover:bg-gray-50 border-gray-300': this.look === 'default' && !this.disabled,
        'text-white bg-cfblue-900 hover:bg-cfblue-600 border-transparent': this.look === 'primary' && !this.disabled,
        'text-white bg-red-600 hover:bg-red-700 border-transparent': this.look === 'danger' && !this.disabled,
        'text-gray-700 hover:bg-gray-100': this.look === 'text' && !this.disabled,
        'text-red-700 hover:bg-red-50': this.look === 'danger_text' && !this.disabled
      }
    }
  },
  methods: {
    handleClick (event) {
      if (this.disabled) {
        return
      }

      if (this.loading) {
        return
      }

      this.$emit('click', event)
    }
  }
}
