//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CfProBadge from '~/components/next/ui/elements/CfProBadge.vue'
import FlashSaleUtils from '@/components/next/layout/flashsale/FlashSaleUtils'

export default {
  name: 'SubscriptionPriceItem',
  components: { CfProBadge },
  mixins: [FlashSaleUtils],
  props: {
    price: {
      type: Object,
      required: true
    },
    selected: Boolean,
    zeroAmountsHidden: Boolean
  },
  computed: {
    title () {
      return this.$t('workspace.billing.subscription.' + this.price.flavor + '.title')
    },

    tagline () {
      return this.$t('workspace.billing.subscription.' + this.price.flavor + '.tagline')
    },

    features () {
      return this.$t('workspace.billing.subscription.' + this.price.flavor + '.included_features')
    },

    additionalFeatures () {
      return this.$t('workspace.billing.subscription.' + this.price.flavor + '.additional_features')
    },

    isPro () {
      return this.price.flavor === 'pro'
    },

    isFree () {
      return this.price.amount === 0
    },

    isYearly () {
      return this.price.cycle === 'yearly'
    },

    discountCode () {
      if (this.flashSaleVisible && this.price.public) {
        return this.flashSalePromotionCode
      }

      return null
    },

    discountFactor () {
      if (this.flashSaleVisible && this.price.public) {
        return 1 - (this.flashSalePercentOff / 100)
      }

      return 1
    },

    topPriceLine () {
      if (this.isFree) {
        return this.zeroAmountsHidden ? null : this.$t('workspace.billing.subscription.price_free')
      }

      if (this.discountCode) {
        return this.formattedMonthlyPrice
      }

      return this.isYearly
        ? this.formattedYearlyPrice
        : this.formattedMonthlyPrice
    },

    bottomPriceLine () {
      if (this.isFree) {
        return
      }

      if (this.discountCode) {
        return this.$t('workspace.billing.subscription.price_with_discount', { code: this.discountCode })
      }

      return this.isYearly
        ? this.formattedMonthlyPrice
        : this.formattedYearlyPrice
    },

    monthlyPrice () {
      return this.price.monthly_amount * this.discountFactor
    },

    yearlyPrice () {
      return this.price.yearly_amount * this.discountFactor
    },

    anchorPrice () {
      if (this.flashSaleVisible && this.price.public) {
        return this.price.monthly_amount
      }

      if (this.yearlyPrice === this.price.anchor_amount) {
        return null
      }

      return this.price.anchor_amount
    },

    formattedAnchorPrice () {
      if (this.anchorPrice === null) {
        return null
      }

      return this.formatCurrencyWithoutDecimals(this.anchorPrice)
    },

    formattedMonthlyPrice () {
      const formattedAmount = this.formatCurrencyWithoutDecimals(this.monthlyPrice)
      return this.$t('workspace.billing.subscription.price_per_month', { amount: formattedAmount })
    },

    formattedYearlyPrice () {
      const formattedAmount = this.formatCurrencyWithoutDecimals(this.yearlyPrice)
      return this.$t('workspace.billing.subscription.price_per_year', { amount: formattedAmount })
    }
  },
  methods: {
    formatCurrencyWithoutDecimals (amount) {
      const decimalAmount = amount / 100
      const currencySuffix = ' €'

      if (amount % 100 === 0) {
        return decimalAmount + currencySuffix
      }

      return decimalAmount.toFixed(2) + currencySuffix
    },
    select () {
      this.$emit('select')
    }
  }
}
